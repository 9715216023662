<template>
  <section class="teamcard section-indent" :class="[mq, { first: first }]">
    <div class="teamcard-left">
      <div class="teamcard-overlay" />
      <img :src="image" :alt="model.name" />
    </div>
    <div class="teamcard-right">
      <div>
        <h2>{{ model.name }}</h2>
        <p class="teamcard-role">{{ model.role }}</p>
      </div>
      <p>{{ model.value }}</p>
    </div>
  </section>
</template>

<script>
export default {
  inject: ["mq"],
  props: {
    first: Boolean,
    model: Object,
  },
  computed: {
    image() {
      return require("@/assets/images/" + this.model.img);
    },
  },
};
</script>

<style scoped>
.teamcard {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 60px;
}

:not(.brMobile).teamcard.first {
  flex-direction: row !important;
}

.teamcard:nth-child(even) {
  flex-direction: row-reverse;
}

.brMobile.teamcard {
  flex-direction: column;
}

.teamcard > div {
  flex: 1;
}

.teamcard-left {
  justify-content: flex-end;
  border-radius: 80px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  height: 350px;
}

.brMobile .teamcard-left {
  border-radius: 50px;
}

.teamcard-overlay {
  background: var(--primary-transparent);
  transition: var(--animation-long);
  position: absolute;
  height: 100%;
  z-index: 10;
  width: 100%;
  left: 0;
  top: 0;
}

.teamcard-left:hover > .teamcard-overlay {
  opacity: 0;
}

.teamcard-left > img {
  transition: var(--animation-long);
  object-position: top;
  filter: grayscale(1);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.teamcard-left:hover > img {
  filter: grayscale(0);
}

.teamcard-right {
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 100%;
  gap: 20px;
}

:not(.brMobile).teamcard.first .teamcard-right {
  text-align: start !important;
}

:not(.brMobile).teamcard:nth-child(even) .teamcard-right {
  text-align: end;
}

.brMobile .teamcard-right {
  text-align: center;
}

.teamcard-right > div {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.teamcard-role {
  font-size: var(--font-small);
  color: var(--accent);
  font-weight: 500;
}
</style>
