<template>
  <Page id="ourteam">
    <section
      class="section-indent section-top page-top"
      aria-labelledby="aria-ourteam"
    >
      <h1 id="aria-ourteam">Meet The Team</h1>
      <p>
        Meet the friendly team that is consistently delivering a professional
        and friendly service to of all our patients.
      </p>
    </section>
    <TeamCard
      :first="index == 0 ? true : null"
      v-for="(d, index) in dentists"
      :key="d.name"
      :model="d"
    />
  </Page>
</template>

<script>
import TeamCard from "@/components/pages/ourteam/TeamCard.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: { TeamCard, Page },
  data() {
    return {
      dentists: [
        {
          role: "Dentist & Owner",
          img: "dentist-w.webp",
          name: "Dr. Jane Doe",
          value:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet reiciendis totam nostrum doloremque tempora perferendis magnam aspernatur facilis repellendus maiores. Reiciendis asperiores rem explicabo ratione sapiente vel cum! Nobis, voluptatum!",
        },
        {
          role: "Dentist",
          img: "dentist-m.webp",
          name: "Dr. John Smith",
          value:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet reiciendis totam nostrum doloremque tempora perferendis magnam aspernatur facilis repellendus maiores. Reiciendis asperiores rem explicabo ratione sapiente vel cum! Nobis, voluptatum!",
        },
        {
          role: "Dentist",
          img: "dentist-m-2.webp",
          name: "Dr. Darren Powell",
          value:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet reiciendis totam nostrum doloremque tempora perferendis magnam aspernatur facilis repellendus maiores. Reiciendis asperiores rem explicabo ratione sapiente vel cum! Nobis, voluptatum!",
        },
        {
          role: "Dental Hygienist",
          img: "dentist-w-2.webp",
          name: "Dr. Emma Laing",
          value:
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet reiciendis totam nostrum doloremque tempora perferendis magnam aspernatur facilis repellendus maiores. Reiciendis asperiores rem explicabo ratione sapiente vel cum! Nobis, voluptatum!",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
